import { render, staticRenderFns } from "./FIFSurveyForm.vue?vue&type=template&id=f9e8392a&scoped=true&"
import script from "./FIFSurveyForm.vue?vue&type=script&lang=ts&setup=true&"
export * from "./FIFSurveyForm.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./FIFSurveyForm.vue?vue&type=style&index=0&id=f9e8392a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f9e8392a",
  null
  
)

export default component.exports